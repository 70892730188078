import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getCompanies, getCurrentCompany } from '../../api/companyFunctions';
import { fetchObjects } from '../../api/crudFunctions';
import { uploadFormData } from '../../api/apiFunctions';
import ColorSelector from './ColorSelector';
import FileUploader from './FileUploader';

const CustomThemeSelector = () => {
  const [selectedCompany, setSelectedCompany] = useState('');
  const [companiesList, setCompaniesList] = useState([]);
  const [existingThemeId, setExistingThemeId] = useState(null);
  const [primaryColor, setPrimaryColor] = useState('#000000');
  const [secondaryColor, setSecondaryColor] = useState('#000000');
  const [companyLogo, setCompanyLogo] = useState();
  const history = useHistory();

  const saveChanges = async () => {
    const path = '/api/theme/';

    const response = await uploadFormData({
      url: existingThemeId ? `${path}${existingThemeId}/` : path,
      method: existingThemeId ? 'PATCH' : 'POST',
      data: {
        company: selectedCompany,
        primary_color: primaryColor,
        secondary_color: secondaryColor,
        ...(typeof companyLogo === 'object' && { logo: companyLogo }),
      },
    });

    if (response.ok) return history.push('/');
  };

  useEffect(() => {
    getCompanies().then((companies) => setCompaniesList(companies));
    getCurrentCompany().then((company) => setSelectedCompany(company.id));
  }, []);

  useEffect(() => {
    if (selectedCompany === '') return;
    fetchObjects(`/api/theme/?company=${selectedCompany}`).then(({ results }) => {
      if (results.length) {
        const { primary_color, secondary_color, logo, id } = results[0];
        setExistingThemeId(id);
        setPrimaryColor(primary_color);
        setSecondaryColor(secondary_color);
        setCompanyLogo(logo);
      } else {
        setExistingThemeId(null);
        setPrimaryColor('#319998');
        setSecondaryColor('#a50b5e');
        setCompanyLogo(null);
      }
    });
  }, [selectedCompany]);

  return (
    <div className='d-flex justify-content-center'>
      <div className='card shadow mb-5 w-auto' style={{ minWidth: '800px' }}>
        <div className='card-header bg-primary-ibis py-3'>
          <h6 className='m-0 font-weight-bold text-white'>{`Custom Theme Selection`}</h6>
        </div>
        <div className='my-3 d-flex flex-column align-items-center'>
          <select className='custom-select w-50' value={selectedCompany} onChange={(e) => setSelectedCompany(e.target.value)}>
            <option value='' hidden>
              Select company...
            </option>
            {companiesList.map((company) => {
              return (
                <option key={`company-${company.id}`} value={company.id}>
                  {company.name}
                </option>
              );
            })}
          </select>
        </div>
        {selectedCompany !== '' && (
          <>
            <ColorSelector value={primaryColor} onChange={(e) => setPrimaryColor(e.target.value)} title={'primary'} />
            <ColorSelector value={secondaryColor} onChange={(e) => setSecondaryColor(e.target.value)} title={'secondary'} />
            <FileUploader uploadedImg={companyLogo} setUploadedImg={setCompanyLogo} />
            <div className='d-flex justify-content-center mb-4'>
              <button
                className='btn btn-secondary'
                type='button'
                style={{ margin: '0px 10px 0px 10px', width: '120px' }}
                onClick={() => history.goBack()}
              >
                Cancel
              </button>
              <button className='btn btn-ibis' type='button' style={{ margin: '0px 10px 0px 10px', width: '120px' }} onClick={() => saveChanges()}>
                Save
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CustomThemeSelector;
