import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCompany } from '../../context/companyContext';

const Sidebar = () => {
  const [loggedUser] = useState(JSON.parse(localStorage.getItem('loggedUser')));
  const [homepageUrl, setHomepageUrl] = useState('');
  const { pathname } = useLocation();
  const { companyLogo, currentCompany } = useCompany();

  const getOrigin = () => {
    try {
      return pathname
        .split('/')
        .filter(function (element) {
          return element !== '';
        })
        .at(-1);
    } catch {
      const origin = pathname.split('/').filter(function (element) {
        return element !== '';
      });
      return origin[origin.length - 1];
    }
  };

  useEffect(() => {
    if (!homepageUrl) {
      switch (loggedUser?.role) {
        case 'admin':
          return setHomepageUrl('/');
        case 'optician':
          return setHomepageUrl('/waiting-room');
        case 'patient':
          return setHomepageUrl('/appointments');
        default:
          return setHomepageUrl('/appointments');
      }
    } else {
      document.getElementsByClassName('active')[0]?.classList.remove('active');
      if (pathname === '/') {
        document.getElementById(homepageUrl.split('/')[1])?.parentElement.classList.add('active');
      } else document.getElementById(pathname.split('/')[1])?.parentElement.classList.add('active');
    }
  }, [pathname, homepageUrl, loggedUser]);

  return (
    <>
      {loggedUser && getOrigin() !== 'ros' && getOrigin() !== 'partner' ? (
        <ul className='navbar-nav sidebar-ibis-rebrand sidebar border-right' id='accordionSidebar'>
          <Link className='sidebar-brand d-flex align-items-center justify-content-center' to={homepageUrl}>
            <img src={companyLogo || '/img/rebrand-logo.svg'} width={companyLogo ? '50px' : '100px'} alt='sidebar header' />
          </Link>

          <hr className='sidebar-divider my-0' />

          {loggedUser.role === 'admin' ? (
            <li className='nav-item'>
              <Link className='nav-link' to={`${process.env.PUBLIC_URL}/`} id='dashboard'>
                <i className='fas fa-fw fa-tachometer-alt'></i>
                <span id='content_title'>Dashboard</span>
              </Link>
            </li>
          ) : (
            ''
          )}

          <hr className='sidebar-divider' />
          {loggedUser.role === 'admin' ? (
            <li className='nav-item'>
              <Link className='nav-link' id='patients' to={`${process.env.PUBLIC_URL}/patients`}>
                <i className='fas fa-fw fa-user-alt'></i>
                <span>Patients</span>
              </Link>
            </li>
          ) : (
            ''
          )}

          {loggedUser.role === 'admin' ? (
            <li className='nav-item'>
              <Link className='nav-link' id='opticians' to={`${process.env.PUBLIC_URL}/opticians`}>
                <i className='fas fa-fw fa-user-md'></i>
                <span>Clinicians</span>
              </Link>
            </li>
          ) : (
            ''
          )}
          {loggedUser.role === 'optician' || loggedUser.role === 'admin' ? (
            <li className='nav-item'>
              <Link className='nav-link' id='waiting-room' to={`${process.env.PUBLIC_URL}/waiting-room`}>
                <i className='fas fa-fw fa-door-closed'></i>
                <span>Waiting Room</span>
              </Link>
            </li>
          ) : (
            ''
          )}
          <li className='nav-item'>
            <Link className='nav-link' id='appointments' to={`${process.env.PUBLIC_URL}/appointments`}>
              <i className='fas fa-fw fa-calendar-check'></i>
              <span>Appointments</span>
            </Link>
          </li>
          {loggedUser.role === 'admin' && currentCompany?.questionnaires_enabled ? (
            <li className='nav-item'>
              <Link
                className='nav-link'
                to='#'
                data-toggle='collapse'
                data-target='#collapseQuestionnaire'
                aria-expanded='false'
                aria-controls='collapseQuestionnaire'
                id='questionnaire'
              >
                <i className='fas fa-fw fa-clipboard-list'></i>
                <span>Questionnaire</span>
              </Link>
              <div id='collapseQuestionnaire' className='collapse' aria-labelledby='collapseQuestionnaire' data-parent='#accordionSidebar'>
                <div className='bg-white py-2 collapse-inner rounded'>
                  <h6 className='collapse-header'>Options</h6>
                  <Link className='collapse-item' to={`${process.env.PUBLIC_URL}/questionnaire`}>
                    Questionnaires
                  </Link>
                  <Link className='collapse-item' to={`${process.env.PUBLIC_URL}/questions`}>
                    Questions
                  </Link>
                </div>
              </div>
            </li>
          ) : (
            ''
          )}
          {loggedUser.superuser ? (
            <li className='nav-item'>
              <Link className='nav-link' id='companies' to={`${process.env.PUBLIC_URL}/companies`}>
                <i className='fas fa-fw fa-building'></i>
                <span id='content_title'>Companies</span>
              </Link>
            </li>
          ) : (
            ''
          )}
          {loggedUser.superuser ? (
            <li className='nav-item'>
              <Link className='nav-link' id='administrators' to={`${process.env.PUBLIC_URL}/administrators`}>
                <i className='fas fa-fw fa-user-tie'></i>
                <span id='content_title'>Administrators</span>
              </Link>
            </li>
          ) : (
            ''
          )}
          {loggedUser.role === 'optician' || loggedUser.role === 'admin' ? (
            <li className='nav-item'>
              <Link className='nav-link' id='invitations' to={`${process.env.PUBLIC_URL}/invitations`}>
                <i className='fas fa-fw fa-envelope'></i>
                <span>Invitations</span>
              </Link>
            </li>
          ) : (
            ''
          )}
          {loggedUser.role !== 'patient' && (
            <li className='nav-item'>
              <Link className='nav-link' id='test-results' to={`${process.env.PUBLIC_URL}/test-results`}>
                <i className='fas fa-fw fa-poll-h'></i>
                <span>Test Results</span>
              </Link>
            </li>
          )}
          <hr className='sidebar-divider' />
        </ul>
      ) : (
        ''
      )}
    </>
  );
};

export default Sidebar;
