import { useState } from 'react';

export const useModal = () => {
  const [modalIsOpen, toggleModalIsOpen] = useState(false);
  const toggle = () => toggleModalIsOpen(!modalIsOpen);
  return { modalIsOpen, toggle };
};

export const usePopover = () => {
  const [showPopover, setShowPopover] = useState(false);
  return { showPopover, setShowPopover };
};

export const useSnackbar = () => {
  const [snackbarState, setSnackbarState] = useState({ show: false, message: '', variant: null });

  const showSnackbar = (message, variant) => {
    if (message) {
      setSnackbarState({ show: true, message: message, variant: variant });
    } else setSnackbarState({ show: false, message: '' });
  };

  return { snackbarState, showSnackbar };
};