import { createContext, useContext, useEffect, useState } from 'react';
import { useCrud } from '../context/crudContext';
import { fetchObjects } from '../api/crudFunctions';
import { getCurrentCompany } from '../api/companyFunctions';
import ResponsiveLoadingPage from '../pages/ResponsiveLoadingPage';
import PoweredByIbisvisionBanner from '../components/Misc/PoweredByIbisvisionBanner';

const CompanyContext = createContext();

export const useCompany = () => {
  return useContext(CompanyContext);
};

const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

export const CompanyProvider = ({ children }) => {
  const { addObject, saveObject, detailObject } = useCrud();
  const [companies, setCompanies] = useState(null);
  const [usingCustomTheme, setUsingCustomTheme] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [currentCompany, setCurrentCompany] = useState(null);

  const getCustomTheme = () => {
    if (!loggedUser || loggedUser?.superuser) return setUsingCustomTheme(false);
    fetchObjects('/api/theme/active/').then((theme) => {
      if (theme?.id) {
        const root = document.documentElement;
        const { primary_color, secondary_color, logo } = theme;
        root.style.setProperty('--primary-color', primary_color);
        root.style.setProperty('--secondary-color', secondary_color);
        root.style.setProperty('--gradient', `linear-gradient(90deg, ${primary_color} 10%, ${secondary_color} 100%)`);
        root.style.setProperty('--gradient-180', `linear-gradient(180deg, ${primary_color} 10%, ${secondary_color} 100%)`);
        setCompanyLogo(logo);
        setUsingCustomTheme(true);
      } else return setUsingCustomTheme(false);
    });
  };

  useEffect(() => {
    if (!loggedUser) return;
    const loadingScreenTimeout = setTimeout(() => {
      getCustomTheme();
      if (loggedUser?.role !== 'patient') getCurrentCompany().then((res) => setCurrentCompany(res));
    }, 1000);
    return () => clearTimeout(loadingScreenTimeout);
  }, []);

  const getApiUrl = (companyId) => {
    if (companyId) {
      return `/api/companies/${companyId}/`;
    }

    return '/api/companies/';
  };

  const fetchCompanies = async () => {
    return await fetchObjects(getApiUrl());
  };

  const getCompanies = async () => {
    const dataFromServer = await fetchCompanies();
    const companiesRecords = dataFromServer.results || [];

    setCompanies(companiesRecords);

    return dataFromServer;
  };

  const addCompany = async (companyData) => {
    const newCompany = await addObject(getApiUrl(), companyData);
    if (typeof newCompany === 'object') {
      setCompanies([...companies, newCompany]);
      return newCompany;
    }
  };

  const saveCompany = async (companyId, companyData) => {
    delete companyData.id;
    const existentCompany = await saveObject(getApiUrl(companyId), companyData);
    if (typeof existentCompany === 'object') {
      getCompanies();
      return existentCompany;
    }
  };

  const editCompany = async (companyId) => {
    Array.from(document.querySelectorAll('.is-invalid')).forEach((el) => el.classList.remove('is-invalid'));
    const res = await detailObject(getApiUrl(companyId), 'GET');
    const companyObject = await res.json();

    for (const fieldname in companyObject) {
      const field_element = document.getElementById('edit_field_' + fieldname);
      if (fieldname === 'start' || fieldname === 'expected_end') {
        let datetime_field = companyObject[fieldname].split(' ');
        let field_date = document.getElementById('edit_field_' + fieldname + '_date');
        let field_time = document.getElementById('edit_field_' + fieldname + '_time');
        if (field_date && field_time) {
          field_date.value = datetime_field[0];
          field_time.value = datetime_field[1];
        }
      }
      if (field_element) {
        field_element.value = companyObject[fieldname];
      }
    }
    return companyObject;
  };

  const deleteCompany = async (companyId) => {
    if (window.confirm('Are you sure you want to delete this company?') === true) {
      await detailObject(getApiUrl(companyId), 'DELETE');
      await getCompanies();
      return true;
    } else {
      return false;
    }
  };

  return (
    <CompanyContext.Provider
      value={{ addCompany, saveCompany, editCompany, deleteCompany, companies, setCompanies, getCompanies, companyLogo, currentCompany }}
    >
      {usingCustomTheme && <PoweredByIbisvisionBanner />}
      {loggedUser && (usingCustomTheme === null || (loggedUser?.role !== 'patient' && !currentCompany)) ? (
        <div className='min-vw-100 min-vh-100 d-flex justify-content-center align-items-center'>
          <ResponsiveLoadingPage />
        </div>
      ) : (
        children
      )}
    </CompanyContext.Provider>
  );
};
