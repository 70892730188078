import { useState, useRef, useEffect } from 'react';
import { login, getLoggedUser } from '../../api/authFunctions';
import { useLocation } from 'react-router-dom';

const LoginForm = ({ transitionComplete, initialRender }) => {
  const [loginUsername, setLoginUsername] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [showPassword, toggleShowPassword] = useState(false);
  const loginFormRef = useRef(null);
  const location = useLocation();

  const componentWillExit = () => {
    loginFormRef.current.style.transform = 'translateX(150%)';
    setTimeout(() => {
      loginFormRef.current.ontransitionend = transitionComplete;
    }, 100);
  };

  useEffect(() => {
    if (!initialRender.current) {
      loginFormRef.current.style.transform = 'translateX(0)';
    }
  }, [initialRender, transitionComplete]);

  const performLogin = async (username, password) => {
    document.querySelector('.login-alert').classList.add('d-none');
    var formdata = new FormData();
    formdata.append('username', username);
    formdata.append('password', password);

    var requestOptions = {
      method: 'POST',
      body: formdata,
    };

    const login_result = await login(requestOptions, location);

    if (login_result === true) {
      const loggedUserResult = await getLoggedUser();

      localStorage.setItem('loggedUser', JSON.stringify(loggedUserResult));

      if (localStorage.getItem('__endpoint__') && localStorage.getItem('__endpoint__') !== '/') {
        window.location.href = process.env.PUBLIC_URL + localStorage.getItem('__endpoint__');
      } else {
        if (loggedUserResult.role === 'admin') {
          window.location.href = process.env.PUBLIC_URL + '/';
        } else if (loggedUserResult.role === 'optician') {
          window.location.href = process.env.PUBLIC_URL + '/waiting-room';
        } else if (loggedUserResult.role === 'patient') {
          window.location.href = process.env.PUBLIC_URL + '/appointments';
        }
      }
    } else {
      document.querySelector('.login-alert').classList.remove('d-none');
      document.getElementById('login-btn').blur();
    }
  };

  return (
    <form
      ref={loginFormRef}
      style={{ transform: `translateX(${initialRender ? 0 : '150%'})`, transition: 'transform 0.6s ease' }}
      className='user'
      onSubmit={(e) => {
        e.preventDefault();
        performLogin(loginUsername, loginPassword);
      }}
    >
      <div className='form-group' style={{ minWidth: '200px', maxWidth: '300px', width: '30vw' }}>
        <input
          type='text'
          className='form-control form-control-user'
          id='login_username'
          aria-describedby='usernameHelp'
          placeholder='Username'
          value={loginUsername}
          onChange={(e) => {
            setLoginUsername(e.target.value);
          }}
        />
      </div>
      <div className='form-group d-flex flex-row align-items-center'>
        <input
          type={`${showPassword ? 'text' : 'password'}`}
          className='form-control form-control-user'
          id='login_password'
          placeholder='Password'
          value={loginPassword}
          onChange={(e) => {
            setLoginPassword(e.target.value);
          }}
        />
        <i
          className={`fas fa-fw ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
          style={{ marginLeft: '-2rem', cursor: 'pointer' }}
          onClick={() => toggleShowPassword(!showPassword)}
        />
      </div>

      <button id='login-btn' className='btn btn-ibis btn-user btn-block' style={{ borderRadius: '0.35rem' }} type='submit'>
        Login
      </button>
      <div className='alert alert-warning d-none login-alert text-center' role='alert'>
        Invalid credentials!
      </div>
      <div className='text-center'>
        <span
          className='small text-ibis'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            componentWillExit();
          }}
        >
          Forgot Password?
        </span>
      </div>
    </form>
  );
};

export default LoginForm;
